<template>
	<div class="grid mb-4">
		<div class="title">{{ isNew ? 'Создание ящика' : 'Ящик' }}</div>
		<form @submit.prevent class="tariffs">
			<BaseGroup title="Основные данные" class="">
				<BaseInput v-model="formData.rfid" placeholder="rfid" :required="true" :minlength="3"/>
				<BaseInput v-model="formData.registerDate" type="date" placeholder="Зарегистрирован" :required="true"/>
				<BaseInput v-model="formData.endDate" type="date" placeholder="Списан"/>
				<BaseInput v-model="formData.weight" placeholder="Вес" :required="true" :decimalNumber="true" />
			</BaseGroup>

			<div class="tariffs--controls">
				<BaseButton @click="addBox" :disabled="formIsInvalid"> Сохранить</BaseButton>
			</div>
		</form>
	</div>
</template>

<script>
import { onMounted, ref, watch, nextTick } from 'vue'
import BaseGroup from '../components/ui/BaseGroup'
import BaseInput from '../components/ui/BaseInput'
import BaseButton from '../components/ui/BaseButton'
import { useApi } from '../modules/api'
import { useRoute, useRouter } from 'vue-router'
import { getInputDate } from '@/utils/getInputDate'
import { useAlert } from '@/modules/composition/useAlert'

export default {
	name: 'BoxesNew',
	components: {
		BaseGroup,
		BaseInput,
		BaseButton
	},
	setup () {
		const { errorAlert } = useAlert()
		const route = useRoute()
		const router = useRouter()
		const formData = ref({
			rfid: null,
			endDate: null,
			registerDate: null,
			weight: null
		})
		const isNew = route.name === 'BoxesNew'
		const formIsInvalid = ref(true)

		watch(formData, async () => {
			await nextTick()
			formIsInvalid.value = document.querySelectorAll(':invalid').length > 0
		},
		{ deep: true }
		)

		const addBox = async () => {
			const { post, error } = useApi(isNew ? '/box' : '/box/update')

			const params = {
				...formData.value,
				weight: +formData.value.weight,
				registerDate: new Date(formData.value.registerDate).toISOString()
			}

			if (formData.value.endDate) {
				params.endDate = new Date(formData.value.endDate).toISOString()
			}

			await post(params)

			if (error.value) {
				errorAlert(`Ошибка при сохранении данных! ${error.value?.message}`)
			} else {
				await router.push('/boxes')
			}
		}

		onMounted(async () => {
			if (!isNew) {
				const { get, data } = useApi(`/box/${route.params.id}`)

				await get()

				data.value.registerDate = getInputDate(new Date(data.value.registerDate))
				if (data.value.endDate) {
					data.value.endDate = getInputDate(new Date(data.value.endDate))
				}
				formData.value = data.value
			}
		})

		return {
			isNew,
			formData,
			formIsInvalid,
			addBox
		}
	}
}
</script>

<style lang="scss" scoped>
.tariffs {
	&--controls {
		margin-top: 20px;
	}
}
</style>
